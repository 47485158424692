import { KnowledgeListContainer, ModulesId } from "commons"
import { useAppModuleContext } from "internals"
import { usePatientContext } from "patients"

import { allergyModelBuilder } from "../components/allergyModelBuilder"
import { useAllergies } from "../hooks"

const AllergiesKnowledge = () => {
  const { appModules } = useAppModuleContext()
  const { patientId } = usePatientContext()
  const { allergies, isLoading } = useAllergies({ patientId, key: "KP" })

  return (
    <KnowledgeListContainer
      isLoading={isLoading}
      dataTitle="allergy"
      data={allergies}
      iconDataNotFound={appModules[ModulesId.ALLERGY].getIcon()}
      itemModelBuilder={(item) => allergyModelBuilder(item)}
    />
  )
}

export { AllergiesKnowledge }
