import {
  Account,
  Address,
  CodeableConcept,
  Coding,
  Composition,
  Device,
  Identifier,
  Location,
  Organization,
  PractitionerRole,
  Reference,
  asReference,
} from "fhir"
import { Messages, MessagesMessage } from "primereact/messages"
import { FC, PropsWithChildren, ReactNode, createContext, useCallback, useEffect, useMemo, useRef } from "react"

import {
  ThemeDefinition,
  useBranding,
  useEnabledLabFacilities,
  useEnabledOrgConsents,
  useEnabledPaymentMethods,
} from "administration"
import { PractitionerInfo } from "commons"
import { useMedicationCatalogs } from "commons/meds"
import {
  BILLING_TYPES_CODES,
  EXEMPT_LAB_PAYMENT_ORG_TYPE_CODE,
  EXEMPT_PAYMENT_PELLET_ORG_TYPE_CODE,
  IDENTIFIER_CODE,
  PAYMENT_METHODS,
  PRACTITIONER_ROLE,
  billingTypes,
} from "data"
import { useCheckNewReleaseAvailable } from "hooks"
import { useLoginContext } from "security"
import { SYSTEM_VALUES } from "system-values"
import { isSafariBrowser, openLinkInNewTab } from "utils"

import { LaboratoryFacility } from "commons/types"
import {
  useDiagnosticLaboratories,
  useOrganization,
  useOrganizationAccount,
  useOrganizationPaymentSetup,
  useOrganizationPractitioners,
  useOrganizationUnreadMessagesCount,
  usePractitionerRole,
} from "../hooks"

const generalAlert = window.VITE_APP_GENERAL_ALERT

const OrganizationContext = createContext<State | undefined>(undefined)
OrganizationContext.displayName = "OrganizationContext"

const OrganizationProvider: FC<Props> = ({ children, organizationId }: PropsWithChildren<Props>) => {
  const { loggedInPractitionerId, hasDosespotSetup: hasPractitionerDosespotSetup } = useLoginContext()

  const {
    organization: currentOrganization,
    location,
    rooms,
    roomsRefs,
    devices,
    devicesRefs,
  } = useOrganization(organizationId)

  const { organizationCCAccount, organizationReaderAccounts } = useOrganizationAccount(organizationId)
  const orgBillingAddress = currentOrganization.address?.find(({ use }) => use === "billing")
  const orgBillingTypes = useMemo(
    () =>
      organizationCCAccount?.creditCard?.length
        ? billingTypes
        : billingTypes.filter(({ code }) => code !== BILLING_TYPES_CODES.BILL_PRACTICE),
    [organizationCCAccount],
  )

  const { isAdmin, isNonAdmin, isPractitioner, isStaff, practitionerRolesIndexedByCode, practitionerRoles } =
    usePractitionerRole(loggedInPractitionerId, currentOrganization)
  const loggedInPractitionerRole = practitionerRolesIndexedByCode.practitioner ?? practitionerRoles[0]
  const loggedInPractitionerRoleRef = asReference(loggedInPractitionerRole)

  const {
    organizationPractitionerRefs,
    organizationPractitionersInfo,
    refetch: refetchPractsInfo,
  } = useOrganizationPractitioners({
    organizationId: organizationId,
  })
  const { branding } = useBranding(organizationId)
  useCheckNewReleaseAvailable()
  const { unreadOrganizationMessagesCount } = useOrganizationUnreadMessagesCount(organizationId)

  const messagesRef = useRef<Messages>(null)

  const { link, sendPaymentSetup } = useOrganizationPaymentSetup()
  const { enabledPaymentMethods } = useEnabledPaymentMethods(organizationId)
  const {
    labs: enabledlabsBySetting,
    labLocation,
    labsWithSuppliedPhlebotomist,
    isLoading: isLoadinfLabsBySettings,
  } = useEnabledLabFacilities(organizationId)
  const { enabledConsents } = useEnabledOrgConsents(organizationId)
  const { nutraCatalogs, rxCatalogs, isLoading: isLoadingMedicationCatalogs } = useMedicationCatalogs(organizationId)

  const { diagnosticLabs, isLoading: isLoadingLabsFacility } = useDiagnosticLaboratories(organizationId)

  const { performerLabsEnabled, unrestrictedLabPerformers, labsWithSuppliedPhlebotomistEnabled } = useMemo(
    () =>
      (diagnosticLabs ?? []).reduce(
        (acc, labFacility) => {
          const isEnabled = enabledlabsBySetting.includes(labFacility.ref.id!)
          const isLabWithSuppliedPhlebotomistEnabled = labsWithSuppliedPhlebotomist[labFacility.ref.id!]

          const isUnrestricted =
            Array.from<string>(
              JSON.parse(window.VITE_APP_PERFORMER_AVOID_RESTRICTED_BILLING?.replace(/'/g, '"') ?? "[]"),
            )?.some((unrestrictedIdentifier) =>
              labFacility.lab.identifier?.some(({ value }) => unrestrictedIdentifier === value),
            ) ?? false

          return {
            ...acc,
            ...(isEnabled
              ? {
                  performerLabsEnabled: [...acc.performerLabsEnabled, labFacility],
                  ...(isUnrestricted && labFacility.ref.id
                    ? { unrestrictedLabPerformers: acc.unrestrictedLabPerformers.add(labFacility.ref.id) }
                    : {}),
                  ...(isLabWithSuppliedPhlebotomistEnabled && labFacility.ref.id
                    ? {
                        labsWithSuppliedPhlebotomistEnabled: acc.labsWithSuppliedPhlebotomistEnabled.add(
                          labFacility.ref.id,
                        ),
                      }
                    : {}),
                }
              : {}),
          }
        },
        {
          performerLabsEnabled: Array<LaboratoryFacility>(),
          unrestrictedLabPerformers: new Set<string>(),
          labsWithSuppliedPhlebotomistEnabled: new Set<string>(),
        },
      ),
    [diagnosticLabs, enabledlabsBySetting],
  )

  const isUnrestrictedPerformer = useCallback(
    (performerId: string) => unrestrictedLabPerformers.has(performerId),
    [unrestrictedLabPerformers],
  )

  const isStripeSetupComplete =
    currentOrganization.identifier?.some((identifier) => identifier.system === SYSTEM_VALUES.STRIPE) ?? false

  const hasRootStripeType =
    currentOrganization.type?.some((item) => item.coding?.[0].code === "stripe-root-account") ?? false

  const isExemptPaymentPellet =
    currentOrganization.type?.some(({ coding }) => coding?.[0].code === EXEMPT_PAYMENT_PELLET_ORG_TYPE_CODE) ?? false

  const hasDosespotSetup =
    currentOrganization.identifier?.some(
      (identifier) => identifier.system === SYSTEM_VALUES.ORGANIZATION_DS_IDENTIFIER,
    ) ?? false

  const isExemptLabPayment =
    currentOrganization.type?.some(({ coding }) => coding?.[0].code === EXEMPT_LAB_PAYMENT_ORG_TYPE_CODE) ?? false

  const hasLifefileSystem =
    currentOrganization.identifier?.some((identifier) => identifier.system === SYSTEM_VALUES.LIFEFILE_PRACTICE) ?? false

  const organizationAnIdentifier = currentOrganization.identifier?.find(
    (identifier) => identifier.type?.coding?.[0]?.code === IDENTIFIER_CODE.AN,
  )

  const stripeMessage: MessagesMessage = useMemo(
    () => ({
      severity: "warn",
      sticky: true,
      detail: (
        <div>
          Your practice needs to complete the payment setup process. Please have your Practice Administrator complete
          the payment setup process by &nbsp;
          <button className="underline" onClick={() => sendPaymentSetup(organizationId)}>
            following this link.
          </button>
        </div>
      ),
    }),
    [organizationId, sendPaymentSetup],
  )

  const showStripeWarning = useCallback(() => {
    if (!isStripeSetupComplete && messagesRef.current && !hasRootStripeType) {
      messagesRef.current.replace([stripeMessage])
    }
  }, [isStripeSetupComplete, stripeMessage, hasRootStripeType])

  const showWarningBanners = useCallback(() => {
    const generalWarning = { severity: "warn", sticky: true, detail: <div>{generalAlert}</div> } as MessagesMessage

    if (messagesRef.current) {
      messagesRef.current.show([
        ...(!isStripeSetupComplete && !hasRootStripeType ? [stripeMessage] : []),
        ...(generalAlert ? [generalWarning] : []),
      ])
    }
  }, [isStripeSetupComplete, stripeMessage, hasRootStripeType])

  useEffect(() => {
    if (link) {
      if (isSafariBrowser()) {
        window.location.assign(link)
        return
      }
      openLinkInNewTab(link)
    }
  }, [link])

  useEffect(() => {
    showWarningBanners()
  }, [])

  return (
    <OrganizationContext.Provider
      value={{
        currentOrganization,
        currentOrganizationId: currentOrganization.id as string,
        currentOrganizationBillingAddress: orgBillingAddress,
        currentOrganizationBillingTypes: orgBillingTypes,
        currentOrganizationCCAccount: organizationCCAccount,
        currentOrganizationReaderAccounts: organizationReaderAccounts,
        partOfId: currentOrganization.partOf?.id,
        location,
        rooms,
        roomsRefs,
        devices,
        devicesRefs,
        practitionerRolesIndexedByCode,
        loggedInPractitionerRole,
        loggedInPractitionerRoleRef,
        isAdmin,
        isNonAdmin,
        isPractitioner,
        isStaff,
        branding,
        isStripeSetupComplete,
        showStripeWarning,
        showWarningBanners,
        isExemptPaymentPellet,
        hasDosespotSetup,
        hasPractitionerDosespotSetup,
        isExemptLabPayment,
        unreadOrganizationMessagesCount,
        hasRootStripeType,
        cardReadersEnabled: enabledPaymentMethods?.includes(PAYMENT_METHODS.CARD_READER) ?? false,
        laboratoryLocation: labLocation,
        performerLabsEnabled,
        labsWithSuppliedPhlebotomistEnabled,
        isLoadingLabsFacility: isLoadingLabsFacility || isLoadinfLabsBySettings,
        hasLifefileSystem,
        enabledConsents,
        nutraCatalogs,
        rxCatalogs,
        isLoadingMedicationCatalogs,
        organizationPractitionerRefs,
        organizationPractitionersInfo,
        organizationAnIdentifier,
        refetchPractsInfo,
        isUnrestrictedPerformer,
      }}
    >
      {<Messages ref={messagesRef} className="fixed z-10 left-1/2 -translate-x-1/2" />}
      {children}
    </OrganizationContext.Provider>
  )
}

type State = {
  currentOrganization: Organization
  currentOrganizationId: string
  currentOrganizationBillingAddress?: Address
  currentOrganizationCCAccount?: Account
  currentOrganizationReaderAccounts?: Account[]
  currentOrganizationBillingTypes: Coding[]
  partOfId?: string
  location?: Location
  rooms: Location[]
  roomsRefs: Reference[]
  devices: Device[]
  devicesRefs: Reference[]
  practitionerRolesIndexedByCode: Record<PRACTITIONER_ROLE, PractitionerRole>
  loggedInPractitionerRole: PractitionerRole
  loggedInPractitionerRoleRef: Reference
  isAdmin: boolean
  isNonAdmin: boolean
  isPractitioner: boolean
  isStaff: boolean
  hasLifefileSystem: boolean
  hasPractitionerDosespotSetup: boolean
  branding: ThemeDefinition & { ehrUrl?: string }
  isStripeSetupComplete: boolean
  hasRootStripeType: boolean
  isExemptPaymentPellet: boolean
  hasDosespotSetup: boolean
  isExemptLabPayment: boolean
  unreadOrganizationMessagesCount: number
  showWarningBanners(): void
  showStripeWarning(): void
  cardReadersEnabled: boolean
  laboratoryLocation: Record<string, string>
  performerLabsEnabled: LaboratoryFacility[]
  labsWithSuppliedPhlebotomistEnabled: Set<string>
  isLoadingLabsFacility: boolean
  enabledConsents: { coding: Coding; flag?: CodeableConcept[] }[]
  nutraCatalogs: Composition[]
  rxCatalogs: Composition[]
  isLoadingMedicationCatalogs: boolean
  organizationPractitionerRefs: Reference[]
  organizationPractitionersInfo: PractitionerInfo[]
  organizationAnIdentifier?: Identifier
  refetchPractsInfo(): void
  isUnrestrictedPerformer(performerId: string): boolean
}

type Props = {
  children: ReactNode
  organizationId: string
}

export { OrganizationContext, OrganizationProvider }
