import { Address, MedicationRequest } from "fhir"
import isEqual from "lodash/isEqual"
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { useChargeItemDefinitions } from "commons"
import { useMrOrderDetails } from "commons/meds"
import { BILLING_TYPES_CODES } from "data"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"
import { getMedCodes, getPriceByCode } from "utils"

import { useMedicationRequestDataBind } from "../../../hooks"
import { MrOrderContext } from "../../../hooks/useMrOrderContext"
import { EDIT_ORDER_STEPS, InvoiceData } from "../../../types"

const MrOrderProvider: FC<Props> = ({ children }) => {
  const { patientId, patient } = usePatientContext()
  const { currentOrganizationId, currentOrganization } = useOrganizationContext()

  const [currentStep, setCurrentStep] = useState<EDIT_ORDER_STEPS>(EDIT_ORDER_STEPS.CONFIG)
  const [invoiceData, setInvoiceData] = useState<InvoiceData>()

  const [params] = useSearchParams()
  const orderId = params.get("edit-order")

  const {
    serviceRequest,
    medicationKnowledges,
    medicationRequests,
    medicationDispenses,
    invoice,
    isLoading,
    billingTypeCode,
    isEditable,
  } = useMrOrderDetails(patientId, orderId as string)

  const [tempMedicationRequests, setTempMedicationRequests] = useState(medicationRequests ?? [])
  const medCodes = useMemo(() => getMedCodes({ meds: tempMedicationRequests, withQty: true }), [tempMedicationRequests])
  const orderShippingAddress = tempMedicationRequests[0]?.dispenseRequest?.shippingAddress

  const editedMedicationRequests = useMemo(() => {
    const edited = new Map<string, MedicationRequest>()
    tempMedicationRequests.forEach((tempMR, index) => {
      const { nextRefillDate: _a, ...tempDispenseRequest } = tempMR.dispenseRequest ?? {}
      const tempMRWithoutRefillDate: MedicationRequest = {
        ...tempMR,
        dispenseRequest: tempDispenseRequest,
      }
      const { nextRefillDate: _b, ...dispenseRequest } = medicationRequests?.[index].dispenseRequest ?? {}
      const mrWithoutRefillDate: MedicationRequest = {
        ...(medicationRequests?.[index] ?? ({} as MedicationRequest)),
        dispenseRequest,
      }

      if (!isEqual(tempMRWithoutRefillDate, mrWithoutRefillDate)) {
        edited.set(tempMR.id!, tempMR)
      }
    })
    return edited
  }, [medicationRequests, tempMedicationRequests])

  useEffect(() => {
    setTempMedicationRequests(medicationRequests ?? [])
  }, [medicationRequests])

  const { chargeItemDefinitions } = useChargeItemDefinitions({
    organizationId: currentOrganizationId,
    codes: {
      ...(billingTypeCode === BILLING_TYPES_CODES.BILL_PATIENT
        ? { billToPatientCIDs: medCodes }
        : { billToPracticeOrInsuranceCIDs: medCodes }),
    },
  })

  const { medicationRequestData } = useMedicationRequestDataBind({
    medicationRequests: tempMedicationRequests,
    medicationKnowledges,
    medicationsCIDs:
      billingTypeCode === BILLING_TYPES_CODES.BILL_PATIENT
        ? chargeItemDefinitions?.billToPatientCIDs ?? {}
        : chargeItemDefinitions?.billToPracticeOrInsuranceCIDs ?? {},
    medicationDispenses,
  })

  const medicationRequestDataWithPrice = medicationRequestData.map((item) => ({
    ...item,
    ...(billingTypeCode === BILLING_TYPES_CODES.BILL_PATIENT
      ? {
          patientPrice: getPriceByCode({
            chargeItemDefinitions: chargeItemDefinitions?.billToPatientCIDs ?? {},
            medCoding: item.medicationKnowledge?.code?.coding,
            factor: item.medicationRequestInfo?.dispenseRequest?.quantity?.value,
            includePatientFee: true,
          }),
        }
      : {
          practicePrice: getPriceByCode({
            chargeItemDefinitions: chargeItemDefinitions?.billToPracticeOrInsuranceCIDs ?? {},
            medCoding: item.medicationKnowledge?.code?.coding,
            factor: item.medicationRequestInfo?.dispenseRequest?.quantity?.value,
          }),
        }),
  }))

  const updateMRWithOrderAddress = (mr: MedicationRequest, orderAddress?: Address) => ({
    ...mr,
    dispenseRequest: { ...mr.dispenseRequest, shippingAddress: orderAddress },
  })

  const updateMedicationRequest = (updatedMR: MedicationRequest) => {
    setTempMedicationRequests((tempMRs) => tempMRs.map((tempMR) => (tempMR.id === updatedMR.id ? updatedMR : tempMR)))
  }

  const moveStep = useCallback(
    (arg?: { previous: boolean; invoiceData?: InvoiceData }) => {
      switch (currentStep) {
        case EDIT_ORDER_STEPS.CONFIG:
          if (!arg?.previous) setCurrentStep(EDIT_ORDER_STEPS.PREVIEW)

          break
        case EDIT_ORDER_STEPS.PREVIEW:
          if (arg?.previous) setCurrentStep(EDIT_ORDER_STEPS.CONFIG)
          break
      }

      setInvoiceData(arg?.invoiceData)
    },
    [currentStep],
  )

  const updateOrderShippingAddress = (newAddress: Address) => {
    setTempMedicationRequests((prevTempRequests) =>
      prevTempRequests.map((request) => updateMRWithOrderAddress(request, newAddress)),
    )
  }

  return (
    <MrOrderContext.Provider
      value={{
        serviceRequest,
        medicationRequestData,
        invoice,
        isLoading,
        editedMedicationRequests,
        updateMedicationRequest,
        currentStep,
        moveStep,
        medicationRequestDataWithPrice,
        invoiceData,
        patient,
        currentOrganization,
        isEditable,
        orderShippingAddress,
        updateOrderShippingAddress,
      }}
    >
      {children(currentStep)}
    </MrOrderContext.Provider>
  )
}

type Props = {
  children(_: EDIT_ORDER_STEPS): ReactNode
}

export { MrOrderContext, MrOrderProvider }
