import { CarePlan, MedicationStatement, PlanDefinitionActionArrayActionArray } from "fhir"
import { useState } from "react"

import { PatientMedicationStatements, sanitize } from "medications-statement"
import { usePatientContext } from "patients"

import { QuestionnaireData } from "../../../../types"
import { useAddMedStatement, useCPManualMedStatements } from "../../../hooks"
import { getActionQuestionnaireInfo } from "../../../utils"
import { SupportingInfo } from "./SupportingInfo"

const ManualMedStatements = ({ carePlan, action, questionnaireData }: Props) => {
  const { patientId } = usePatientContext()

  const [reset, setReset] = useState(false)

  const { medications, isLoading } = useCPManualMedStatements(patientId, carePlan.id as string)
  const { addMedStatement } = useAddMedStatement(() => setReset(true))

  const onSubmit = (medStatement: MedicationStatement) =>
    addMedStatement({ patientId, planId: carePlan.id as string, medStatement: sanitize(medStatement) })

  const qInfo = getActionQuestionnaireInfo(action, questionnaireData)

  return (
    <PatientMedicationStatements
      queryData={{ medications, isLoading }}
      title="Medication Statements"
      className="border-t gap-4 pt-4 first:border-0 first:pt-0"
      onSubmit={onSubmit}
      forceReset={{ reset, setReset }}
      supportingInfo={qInfo && <SupportingInfo questionnaireInfo={qInfo} />}
    />
  )
}

type Props = {
  carePlan: CarePlan
  action: PlanDefinitionActionArrayActionArray
  questionnaireData?: QuestionnaireData[]
}

export { ManualMedStatements }
