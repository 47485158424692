import { AllergyIntolerance, CarePlan, PlanDefinitionActionArrayActionArray } from "fhir"
import { useState } from "react"

import { PatientAllergies, sanitize } from "allergies"
import { usePatientContext } from "patients"

import { QuestionnaireData } from "../../../../types"
import { useAddAllergy, useCPManualAllergies } from "../../../hooks"
import { getActionQuestionnaireInfo } from "../../../utils"
import { SupportingInfo } from "./SupportingInfo"

const ManualAllergies = ({ carePlan, action, questionnaireData }: Props) => {
  const { patientId } = usePatientContext()

  const [reset, setReset] = useState(false)

  const { allergies, isLoading } = useCPManualAllergies(patientId, carePlan.id as string)
  const { addAllergy } = useAddAllergy(() => setReset(true))

  const onSubmit = (allergy: AllergyIntolerance) =>
    addAllergy({ patientId, planId: carePlan.id as string, allergy: sanitize(allergy) })

  const qInfo = getActionQuestionnaireInfo(action, questionnaireData)

  return (
    <PatientAllergies
      queryData={{ allergies, isLoading }}
      title="Allergies"
      className="border-t gap-4 pt-4 first:border-0 first:pt-0"
      onSubmit={onSubmit}
      forceReset={{ reset, setReset }}
      supportingInfo={qInfo && <SupportingInfo questionnaireInfo={qInfo} />}
    />
  )
}

type Props = {
  carePlan: CarePlan
  action: PlanDefinitionActionArrayActionArray
  questionnaireData?: QuestionnaireData[]
}

export { ManualAllergies }
