import { Message } from "primereact/message"
import { FC, MouseEvent } from "react"

import { useChartContext } from "chart-view"
import { ModulesId } from "commons"

const PrescriptionAddressWarningMessage: FC<Props> = ({ message, actionText }) => {
  const { showModule } = useChartContext()

  const navToAddressSubview = (event: MouseEvent) => {
    event.preventDefault()
    showModule({ module: ModulesId.PATIENT, moduleParams: { subview: "address" } })
  }

  return (
    <div className="sticky top-0 z-20 bg-white py-2">
      <Message
        className="w-full"
        severity="warn"
        content={
          <div className="flex gap-2 text-sm">
            <span>{message}.</span>
            <button className="font-medium underline" onClick={navToAddressSubview}>
              {actionText}
            </button>
          </div>
        }
      />
    </div>
  )
}

type Props = {
  message: string
  actionText: string
}

export { PrescriptionAddressWarningMessage }
