import { useQuery } from "@tanstack/react-query"
import { useMemo } from "react"

import { labSuppliedPhlebotomistEnabled } from "administration/data"
import { organizationSettingId } from "data"

import { settingsQueryKeys } from "../../query-keys"
import { useSettingQueryFunction } from "./useSettings"

const useEnabledLabFacilities = (currentOrganizationId: string) => {
  const getSetting = useSettingQueryFunction()
  const queryKey = settingsQueryKeys.labFacilities(currentOrganizationId)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const setting = await getSetting(currentOrganizationId, organizationSettingId.labFacilities)

      return setting
    },
  })

  const { labs, labLocation, labsWithSuppliedPhlebotomist } = useMemo(
    () =>
      data?.items?.reduce<{
        labs: string[]
        labLocation: Record<string, string>
        labsWithSuppliedPhlebotomist: Record<string, boolean>
      }>(
        (acc, item) =>
          item.value?.boolean && item.value.Reference?.id
            ? {
                labs: [...acc.labs, item.value.Reference?.id as string],
                labLocation: {
                  ...acc.labLocation,
                  [item.value.Reference.id]: item.value.uri?.replace("Location/", "") ?? "",
                },
                labsWithSuppliedPhlebotomist: {
                  ...acc.labsWithSuppliedPhlebotomist,
                  [item.value.Reference.id]:
                    item.flag?.find(
                      (codeableConcept) =>
                        codeableConcept?.coding?.[0]?.code === labSuppliedPhlebotomistEnabled.coding?.[0]?.code,
                    ) !== undefined,
                },
              }
            : acc,
        { labs: [], labLocation: {}, labsWithSuppliedPhlebotomist: {} },
      ) ?? { labs: [], labLocation: {}, labsWithSuppliedPhlebotomist: {} },
    [data],
  )

  return { labs, labLocation, labsWithSuppliedPhlebotomist, isLoading }
}

export { useEnabledLabFacilities }
