import { useQuery } from "@tanstack/react-query"
import { Composition, getResources, Money, PlanDefinition, Questionnaire } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { useCIDQueryFunction } from "commons"
import { LaboratoryComboTest, LaboratoryTest } from "commons/types"
import { BILLING_TYPES_CODES, COMBO_PROMO_CODE } from "data"
import { convertIdentifiersToCodings, getBasePrice, getCidIdentifier, getCommonCode, mergeSort } from "utils"

import { labOrdersQueryKeys } from "../query-keys"

const useLaboratoryOrderCombos = (
  organizationId: string,
  catalogAuthorId: string,
  gender?: string,
  billingType?: string,
) => {
  const { search } = useClient()
  const queryKey = labOrdersQueryKeys.comboTests(organizationId, catalogAuthorId)

  const getChargeItemDefinitions = useCIDQueryFunction()

  const { data, isLoading, isError, error } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _query: "laboratory-combo-tests",
        catalogAuthor: catalogAuthorId,
        organization: organizationId,
        ...(gender ? { gender } : undefined),
        _count: "20",
      })

      const bundle = await search({ endpoint: "PlanDefinition", filters, signal })

      const planDefinitions = getResources<PlanDefinition>(bundle, "PlanDefinition")
      const compositions = getResources<Composition>(bundle, "Composition")
      const questionnaires = getResources<Questionnaire>(bundle, "Questionnaire")

      const codes = convertIdentifiersToCodings(planDefinitions)
      const chargeItemDefinitions = await getChargeItemDefinitions(organizationId, {
        billToPatientCIDs: codes,
        billToPracticeOrInsuranceCIDs: codes,
      })

      return {
        planDefinitions,
        compositions,
        chargeItemDefinitions,
        questionnaires,
        total: planDefinitions?.length ?? 0,
      }
    },
    enabled: !!catalogAuthorId,
    meta: { context: { queryKey, catalogAuthorId } },
  })

  const laboratoryCombos = useMemo(() => {
    const combos = data?.planDefinitions.filter((pd) => pd.type?.coding?.[0].code === "combo")

    const indexedQuestionnaires = data?.questionnaires?.reduce<Record<string, Questionnaire>>((acc, cur) => {
      return { ...acc, [`${cur?.url}|${cur.version}`]: cur }
    }, {})

    const indexedPlanDefinitions = data?.planDefinitions
      .filter((pd) => pd.type?.coding?.[0].code === "panel")
      .reduce<Record<string, PlanDefinition>>((acc, pd) => {
        return { ...acc, [`${pd.url}|${pd.version}`]: pd }
      }, {})

    const labCombos = combos?.reduce((acc, combo) => {
      const comboCodes = convertIdentifiersToCodings(combo ? [combo] : [])
      const comboCid =
        billingType === BILLING_TYPES_CODES.BILL_PATIENT
          ? data?.chargeItemDefinitions?.billToPatientCIDs?.[getCidIdentifier(getCommonCode({ codes: comboCodes }))]
          : data?.chargeItemDefinitions?.billToPracticeOrInsuranceCIDs?.[
              getCidIdentifier(getCommonCode({ codes: comboCodes }))
            ]

      const comboPds = combo.action
        ?.filter(({ definition }) => !!definition?.canonical)
        .map((acction) => indexedPlanDefinitions?.[acction.definition?.canonical as string])

      const comboTestData =
        comboPds?.map((pd) => {
          const questionnaireActions = pd?.action?.filter((def) => def.definition?.canonical?.includes("Questionnaire"))
          const pdQuestionnaires = questionnaireActions?.flatMap((qa) =>
            indexedQuestionnaires?.[qa.definition?.canonical as string]
              ? indexedQuestionnaires?.[qa.definition?.canonical as string]
              : [],
          )

          return {
            planDefinition: pd,
            display: pd?.title ?? pd?.name ?? "Unknown",
            questionnaires: pdQuestionnaires,
          } as LaboratoryTest
        }) ?? []

      const newCombo: LaboratoryComboTest = {
        combo,
        price: getBasePrice(comboCid?.propertyGroup?.[0].priceComponent)?.amount as Money,
        laboratoryTests: comboTestData,
      }

      return [...acc, newCombo]
    }, new Array<LaboratoryComboTest>())

    const { withPromo, sortedOnes } = mergeSort(
      labCombos ?? [],
      "price",
      (a: Money | undefined, b: Money | undefined) => (a?.value ?? 0) - (b?.value ?? 0),
    ).reduce(
      (acc, labCombo) => {
        const isPromoCombo = labCombo.combo?.topic?.some((topic) => topic.coding?.[0]?.code === COMBO_PROMO_CODE)
        if (isPromoCombo) {
          acc.withPromo.push(labCombo)
        } else {
          acc.sortedOnes.push(labCombo)
        }
        return acc
      },
      { withPromo: [] as LaboratoryComboTest[], sortedOnes: [] as LaboratoryComboTest[] },
    )

    return [...sortedOnes, ...withPromo]
  }, [data, billingType])

  return { laboratoryCombos, isLoading, isError, error }
}

export { useLaboratoryOrderCombos }
