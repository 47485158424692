import { Dosage, MedicationKnowledge, MedicationKnowledgeAdministrationGuidelinesArray } from "fhir"
import { useFormikContext } from "formik"
import { FC } from "react"

import { PatientAllergies } from "allergies"
import {
  ModulesId,
  NumberField,
  PractitionerInfo,
  PractitionerRoleDropdownField,
  ReferenceDropdownField,
  TextAreaField,
} from "commons"
import { getAdministrationGuideline } from "commons/meds"
import { PatientConditions } from "conditions"
import { useAppModuleContext } from "modules"
import { useOrganizationContext } from "organization"

import { MedicationRequestFormData } from "../../types"
import { getMKDispenseLimit } from "../../utils"
import { PrescriptionSigProvider } from "../prescriptions/context/PrescriptionSigContext"
import { AutocompleteMedicationField } from "./AutocompleteMedicationField"
import { DispenseRequestFields } from "./DispenseRequestFields"
import { PrescriptionDosages } from "./PrescriptionDosages"
import { PrescriptionPrices } from "./PrescriptionPrices"
import { PrescriptionShippingAddressField } from "./PrescriptionShippingAddressField"
import { PrescriptionUnitField } from "./PrescriptionUnitField"

const PrescriptionForm: FC<Props> = ({
  practitionersInfo,
  isEditing,
  allowedMedicationCodes,
  medRecommendedDosage,
  hidePrescriberField,
  editAddressHidden,
}) => {
  const { rxCatalogs } = useOrganizationContext()
  const { isModuleActive } = useAppModuleContext()
  const { values, isSubmitting /*setFieldValue*/ } = useFormikContext<MedicationRequestFormData>()
  const { medicationField } = values
  const hasPackaging = !!(medicationField as MedicationKnowledge)?.packaging?.type
  const catalogsAuthorRef = rxCatalogs.map((c) => c.author[0])
  const medicationDispenseLimit = getMKDispenseLimit(medicationField as MedicationKnowledge)

  // const { isLoadingAvailability, medAvailability, medQuantityAvailable, refetchMedAvailability } =
  //   useMedicationAvailability(
  //     medicationField?.id ?? (values?.medicationKnowledge?.id as string),
  //     values?.dispenseRequest?.quantity?.value ?? 1,
  //   )
  // const [isDFO, setIsDFO] = useState(values?.dispenseRequest?.initialFill?.isDfo ?? false)
  // const [warningAvailability, setWarningAvailability] = useState(!medQuantityAvailable)

  // useEffect(() => {
  //   if (!isLoadingAvailability && !medQuantityAvailable && !isSubmitting) {
  //     setFieldValue("dispenseRequest.initialFill.isDfo", false)
  //     setIsDFO(false)
  //     setWarningAvailability(true)
  //   }
  // }, [isLoadingAvailability, medQuantityAvailable, isSubmitting])

  // const updateDFO = async (state: boolean) => {
  //   setIsDFO(state)
  //   if (state) {
  //     const { data } = await refetchMedAvailability()
  //     setWarningAvailability(!data?.medQuantityAvailable)
  //     data?.medQuantityAvailable ? setFieldValue("dispenseRequest.initialFill.isDfo", true) : setIsDFO(false)
  //   } else setFieldValue("dispenseRequest.initialFill.isDfo", false)
  // }

  return (
    <>
      <div className="p-fluid relative border-b pb-4">
        <AutocompleteMedicationField
          field="medicationField"
          disabled={isEditing}
          label="Medication"
          allowedMedicationCodes={allowedMedicationCodes}
        />
      </div>
      <PrescriptionSigProvider
        medRecommendedDosage={medRecommendedDosage}
        getAdministrationGuideline={getAdministrationGuideline}
      >
        <PrescriptionDosages />
      </PrescriptionSigProvider>
      <div className="flex space-x-6 pb-4">
        {!hidePrescriberField && (
          <PractitionerRoleDropdownField
            field="requester"
            label="Prescriber"
            options={practitionersInfo ?? []}
            className="flex-1"
            disabled={isSubmitting}
            useFilter={false}
          />
        )}
        <ReferenceDropdownField
          className="flex-1"
          label="Pharmacy"
          options={catalogsAuthorRef}
          field="dispenseRequest.performer"
          filter={false}
        />
      </div>
      <div className="flex flex-col">
        <div className="flex gap-y-3 gap-x-6 flex-wrap">
          <div className="flex flex-1 justify-between space-x-6 mb-1">
            <NumberField
              field="dispenseRequest.quantity.value"
              label="Quantity"
              min={1}
              disabled={isSubmitting}
              max={medicationDispenseLimit}
              subMessage={
                medicationDispenseLimit
                  ? `Max dispense limit for this medication is (${medicationDispenseLimit})`
                  : undefined
              }
              className="w-full"
            />
            <PrescriptionUnitField disabled={hasPackaging} />
          </div>
          <DispenseRequestFields />
        </div>
        {(medicationField?.id || medicationField?.code) && (
          <div className="flex justify-between items-center border-t py-5">
            {/* <div className="flex justify-between items-center gap-2 w-28">
              <span className="text-sm inline-flex items-center" title="Dispense from office">
                DFO
                {isDFO && isLoadingAvailability && <FontAwesomeIcon icon={faCircleNotch} spin className="ml-2" />}
                {!medQuantityAvailable && (warningAvailability || isDFO) && !isLoadingAvailability && (
                  <span
                    id={`warningAvailability${values.id}`}
                    className={classNames({ "p-disabled": isSubmitting })}
                    data-pr-tooltip={`Warning: There is no availability to dispense this medication from office.
                          ${
                            medAvailability
                              ? `There is only ${medAvailability} ${pluralize(values?.dispenseRequest?.quantity?.unit?.toLowerCase() ?? "unit", medAvailability)} available.`
                              : ""
                          }`}
                  >
                    <FontAwesomeIcon icon={faExclamationTriangle} className="ml-2 text-lg" />
                    <Tooltip target={`#warningAvailability${values.id}`} position="bottom" />
                  </span>
                )}
              </span>
              <InputSwitch checked={isDFO} onChange={(e) => updateDFO(e.value)} disabled={isSubmitting} />
            </div> */}
            <PrescriptionPrices />
          </div>
        )}
      </div>

      {!editAddressHidden && <PrescriptionShippingAddressField fieldName="dispenseRequest.shippingAddress" />}

      <TextAreaField
        field="note[0].text"
        label="Note"
        rows={5}
        className="w-full mt-2"
        inputClassName="h-full"
        disabled={isSubmitting}
      />
      {isModuleActive(ModulesId.INTAKE) && (
        <>
          <PatientConditions />
          <PatientAllergies />
        </>
      )}
    </>
  )
}

type Props = {
  practitionersInfo: PractitionerInfo[]
  isEditing?: boolean
  allowedMedicationCodes?: string[]
  medRecommendedDosage?: Record<string, Dosage[]>
  getAdministrationGuideline?: (
    mk: MedicationKnowledge,
    medRecommendedDosage?: Record<string, Dosage[]>,
  ) => MedicationKnowledgeAdministrationGuidelinesArray | undefined
  hidePrescriberField?: boolean
  editAddressHidden?: boolean
}

export { PrescriptionForm }
