import { useInfiniteQuery } from "@tanstack/react-query"
import { AllergyIntolerance, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { allergiesQueryKeys } from "../query-keys"

const useAllergies = ({
  patientId,
  key,
  encounter,
  enabled,
}: {
  patientId: string
  key?: string
  encounter?: string
  enabled?: boolean
}) => {
  const { search } = useClient()
  const queryKey = allergiesQueryKeys.list(patientId, key, encounter)

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<AllergyQueryData, Error>(
    {
      queryKey,
      queryFn: async ({ pageParam = 1, signal }) => {
        const filters = new URLSearchParams({
          _count: "20",
          _page: `${pageParam}`,
          _sort: "-date",
          "verification-status:not": "refuted",
          ...(encounter ? { encounter } : {}),
        })

        const bundle = await search({ endpoint: `Patient/${patientId}/AllergyIntolerance`, filters, signal })

        const allergies = getResources<AllergyIntolerance>(bundle, "AllergyIntolerance")

        const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

        return { allergies, next, total: bundle?.total ?? 0 }
      },
      getNextPageParam: (lastPage) => lastPage.next,
      initialPageParam: 1,
      meta: { context: { queryKey, patientId } },
      enabled,
    },
  )

  const { allergies, count } = useMemo(() => {
    const newData = data?.pages.flatMap((page) => page.allergies)
    const count = newData?.length

    return {
      allergies: newData,
      count,
    }
  }, [data?.pages])

  return {
    allergies,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type AllergyQueryData = {
  allergies: AllergyIntolerance[]
  next: number | undefined
  total: number
}

export { useAllergies }
