import { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import { FormikValues } from "formik"

import { SearchWithFiltersProps } from "../components/SearchWithFilters"
import { Slideover, SlideoverProps } from "../components/Slideover"
import { StackedListItemProps } from "../components/StackedListItem"
import { SuggestionProps } from "../types"
import { CheckMultipleWithFilter } from "./CheckMultipleWithFilter"

const CheckMultipleWithSlideoverFilter = <T extends FormikValues>({
  isVisible,
  initialCheckedValues,
  initialCheckedIds,
  icon,
  queryProps: { data, isFetching, hasNextPage, fetchNextPage },
  filterProps,
  verifyExistInData,
  suggestionProps,
  title,
  emptyLabel,
  width = "35%",
  height = "calc(100% - 9.5rem)",
  position = "right",
  dismissable = false,
  childrenContainerClassName = "flex flex-col flex-1 overflow-hidden",
  cancelLabel = "Close",
  acceptLabel = "Accept",
  getItemId,
  getItemText,
  onHide,
  onSave,
  itemModel,
  evaluateExtraCheckedCondition,
  evaluateIsDisabled,
  maxSelectedItemsAllowed,
  ...slideProps
}: Props<T>) => (
  <Slideover
    {...{ width, height, position, dismissable, childrenContainerClassName, cancelLabel, acceptLabel }}
    {...slideProps}
    title={title}
    onHide={() => onHide?.()}
    showSlide={isVisible}
    showButtons={false}
    showCloseIcon
  >
    <CheckMultipleWithFilter
      acceptLabel={acceptLabel}
      cancelLabel={cancelLabel}
      filterProps={filterProps}
      itemModel={itemModel}
      queryProps={{ data, hasNextPage, isFetching, fetchNextPage }}
      verifyExistInData={verifyExistInData}
      initialCheckedIds={initialCheckedIds}
      initialCheckedValues={initialCheckedValues}
      suggestionProps={suggestionProps}
      emptyLabel={emptyLabel}
      icon={icon}
      getItemId={getItemId}
      getItemText={getItemText}
      onHide={onHide}
      onSave={onSave}
      evaluateExtraCheckedCondition={evaluateExtraCheckedCondition}
      evaluateIsDisabled={evaluateIsDisabled}
      maxSelectedItemsAllowed={maxSelectedItemsAllowed}
    />
  </Slideover>
)

type Props<T> = {
  initialCheckedIds?: string[]
  initialCheckedValues?: Array<T>
  onSave(newCheckedItems: Array<T>, deletedItems: { id: string; index: number }[]): void
  itemModel(item: T): StackedListItemProps
  onHide?(): void
  getItemId?(item: T): string
  getItemText?(item: T): string
  icon: IconDefinition
  queryProps: {
    data?: Array<T>
    isFetching: boolean
    hasNextPage?: boolean
    fetchNextPage?(): void
  }
  emptyLabel?: string
  evaluateExtraCheckedCondition?(item: T, deletedItems: { id: string; index: number }[]): boolean
  evaluateIsDisabled?(item: T, deletedItems: { id: string; index: number }[]): boolean
  filterProps: SearchWithFiltersProps<FormikValues>
  isVisible: boolean
  verifyExistInData?: boolean
  suggestionProps?: SuggestionProps<T>
  title: string
  maxSelectedItemsAllowed?: number
} & Omit<SlideoverProps, "showSlide">

export { CheckMultipleWithSlideoverFilter }
