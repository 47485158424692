import { unitOfTime } from "data"
import { Timing } from "fhir"
import { confirmDialog } from "primereact/confirmdialog"

import { SYSTEM_VALUES } from "system-values"

enum PrescriptionViews {
  prescriptions = "prescriptions",
  history = "history",
  orders = "orders",
  refills = "refills",
}

const prescriptionViewOptions = [
  { name: "Prescriptions", value: PrescriptionViews.prescriptions },
  { name: "Scheduled Orders", value: PrescriptionViews.refills },
  { name: "History", value: PrescriptionViews.history },
  { name: "Pending Orders", value: PrescriptionViews.orders },
]

const durationCodeOptions = unitOfTime.map((uot) => ({ label: uot.display, value: { ...uot } }))

const prescriptionFrequencies = [
  { label: "Once / day", value: "oncePerDay" },
  { label: "Twice / day", value: "twicePerDay" },
  { label: "Three times / day", value: "threePerDay" },
  { label: "Four times / day", value: "fourPerDay" },
  { label: "Every morning", value: "everyMorning" },
  { label: "Every night", value: "everyNight" },
  { label: "Every other day", value: "everyOtherDay" },
  { label: "Once / week", value: "oncePerWeek" },
  { label: "Twice / week", value: "twicePerWeek" },
  { label: "Three times / week", value: "threePerWeek" },
  { label: "Four times / week", value: "fourPerWeek" },
  { label: "Five times / week", value: "fivePerWeek" },
  { label: "Eight times / week", value: "eightPerWeek" },
  { label: "Five times / month", value: "fivePerMonth" },
  { label: "Seven times / month", value: "sevenPerMonth" },
  { label: "Fifteen times / month", value: "fifteenPerMonth" },
  { label: "Twenty five times / month", value: "twentyFivePerMonth" },
]

const routeOptions = [
  {
    label: "Mouth",
    value: {
      code: "oral",
      system: SYSTEM_VALUES.VS_ROUTE_CODE,
      display: "Oral",
    },
  },
  {
    label: "Sublingual",
    value: { code: "37839007", display: "Sublingual use", system: SYSTEM_VALUES.VS_ROUTE_CODE },
  },
  {
    label: "Nose",
    value: { code: "37839007", display: "Nasal use", system: SYSTEM_VALUES.VS_ROUTE_CODE },
  },
  {
    label: "Intravenous",
    value: { code: "476220.value08", display: "Intravenous use", system: SYSTEM_VALUES.VS_ROUTE_CODE },
  },
  {
    label: "Intramuscular",
    value: { code: "78421000", display: "Intramuscular use", system: SYSTEM_VALUES.VS_ROUTE_CODE },
  },
  {
    label: "Subcutaneous",
    value: {
      code: "34206005",
      system: SYSTEM_VALUES.VS_ROUTE_CODE,
      display: "Subcutaneous route",
    },
  },
  {
    label: "Injection",
    value: {
      code: "injection",
      system: SYSTEM_VALUES.VS_ROUTE_CODE,
      display: "Injection",
    },
  },
  {
    label: "Topical",
    value: {
      code: "topical",
      system: SYSTEM_VALUES.VS_ROUTE_CODE,
      display: "Topical",
    },
  },
]

const dosageTimingRepeats = [
  {
    id: "oncePerDay",
    code: {
      coding: [
        {
          code: "QD",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 1,
      period: 1,
      periodUnit: "d",
    },
  },
  {
    id: "twicePerDay",
    code: {
      coding: [
        {
          code: "BID",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 2,
      period: 1,
      periodUnit: "d",
    },
  },
  {
    id: "threePerDay",
    code: {
      coding: [
        {
          code: "TID",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 3,
      period: 1,
      periodUnit: "d",
    },
  },
  {
    id: "fourPerDay",
    code: {
      coding: [
        {
          code: "QID",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 4,
      period: 1,
      periodUnit: "d",
    },
  },
  {
    id: "everyOtherDay",
    code: {
      coding: [
        {
          code: "QD",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 1,
      period: 2,
      periodUnit: "d",
    },
  },
  {
    id: "everyMorning",
    code: {
      coding: [
        {
          code: "AM",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 1,
      period: 1,
      periodUnit: "d",
      when: ["MORN"],
    },
  },
  {
    id: "everyNight",
    code: {
      coding: [
        {
          code: "BED",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 1,
      period: 1,
      periodUnit: "d",
      when: ["NIGHT"],
    },
  },
  {
    id: "oncePerWeek",
    code: {
      coding: [
        {
          code: "QD",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 1,
      period: 1,
      periodUnit: "wk",
    },
  },
  {
    id: "twicePerWeek",
    code: {
      coding: [
        {
          code: "BID",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 2,
      period: 1,
      periodUnit: "wk",
    },
  },
  {
    id: "threePerWeek",
    code: {
      coding: [
        {
          code: "TID",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 3,
      period: 1,
      periodUnit: "wk",
    },
  },
  {
    id: "fourPerWeek",
    code: {
      coding: [
        {
          code: "QID",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 4,
      period: 1,
      periodUnit: "wk",
    },
  },
  {
    id: "fivePerWeek",
    code: {
      coding: [
        {
          code: "WK",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 5,
      period: 1,
      periodUnit: "wk",
    },
  },
  {
    id: "eightPerWeek",
    code: {
      coding: [
        {
          code: "WK",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 8,
      period: 1,
      periodUnit: "wk",
    },
  },
  {
    id: "oncePerMonth",
    code: {
      coding: [
        {
          code: "QD",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 1,
      period: 1,
      periodUnit: "mo",
    },
  },
  {
    id: "fivePerMonth",
    code: {
      coding: [
        {
          code: "QD",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 5,
      period: 1,
      periodUnit: "mo",
    },
  },
  {
    id: "sevenPerMonth",
    code: {
      coding: [
        {
          code: "QD",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 7,
      period: 1,
      periodUnit: "mo",
    },
  },
  {
    id: "fifteenPerMonth",
    code: {
      coding: [
        {
          code: "QD",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 15,
      period: 1,
      periodUnit: "mo",
    },
  },
  {
    id: "twentyFivePerMonth",
    code: {
      coding: [
        {
          code: "QD",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 25,
      period: 1,
      periodUnit: "mo",
    },
  },
] as Timing[]

const medicationFormOptions = [
  { label: "Oral drops", value: [{ code: "385018001", display: "Oral drops", system: "ttp://snomed.info/sct" }] },
  {
    label: "Solution for injection",
    value: [{ code: "385219001", display: "Solution for injection", system: "ttp://snomed.info/sct" }],
  },
  { label: "Oral tablet", value: [{ code: "421026006", display: "Oral tablet", system: "ttp://snomed.info/sct" }] },
  { label: "Ear solution", value: [{ code: "421713001", display: "Ear solution", system: "ttp://snomed.info/sct" }] },
  {
    label: "Nasal solution",
    value: [{ code: "422336005", display: "Nasal solution", system: "ttp://snomed.info/sct" }],
  },
]

const NO_FOUND_MEDICATIONS = "No medications found"

const stopMR = (medReqId: string, onAcceptCliked: (mrId: string) => void) => {
  confirmDialog({
    message: "Are you sure you want to stop this prescription?",
    header: "Confirmation",
    acceptLabel: "Stop",
    rejectLabel: "Cancel",
    rejectClassName: "button-default p-button-sm",
    acceptClassName: "button-primary p-button-sm",
    accept: () => onAcceptCliked(medReqId),
  })
}

const completeMR = (medReqId: string, onAcceptCliked: (mrId: string) => void) => {
  confirmDialog({
    message: "Are you sure you want to mark as completed this prescription?",
    header: "Confirmation",
    acceptLabel: "Complete",
    rejectLabel: "Cancel",
    rejectClassName: "button-default p-button-sm",
    acceptClassName: "button-primary p-button-sm",
    accept: () => onAcceptCliked(medReqId),
  })
}

export {
  NO_FOUND_MEDICATIONS,
  PrescriptionViews,
  completeMR,
  dosageTimingRepeats,
  durationCodeOptions,
  medicationFormOptions,
  prescriptionFrequencies,
  prescriptionViewOptions,
  routeOptions,
  stopMR,
}
