import { useMutation, useQueryClient } from "@tanstack/react-query"
import { MedicationStatement } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { medicationStatementQueryKeys } from "medications-statement"
import { displayNotificationSuccess } from "utils"

import { plansQueryKeys } from "../query-keys"

const useAddMedStatement = (onSettled: () => void) => {
  const { create, update } = useClient()
  const queryClient = useQueryClient()

  const newMedStatement = async ({
    planId,
    medStatement,
  }: {
    patientId: string
    planId: string
    medStatement: MedicationStatement
  }) =>
    medStatement.id
      ? update("MedicationStatement", medStatement.id as string, medStatement)
      : create(`CarePlan/${planId}/$add-resource`, medStatement)

  const { mutate: addMedStatement, isPending: isAdding } = useMutation({
    mutationFn: newMedStatement,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { patientId, planId, medStatement }) => {
      queryClient.refetchQueries({
        queryKey: plansQueryKeys.manualMedStatements(patientId, planId),
        type: "all",
      })
      await queryClient.invalidateQueries({
        queryKey: medicationStatementQueryKeys.list(patientId),
        refetchType: "active",
      })
      displayNotificationSuccess(`Medication ${medStatement.id ? "updated" : "added"} successfully!`)
      // datadogLogs.logger.info(`Manual medication statement of plan ${planId} ${condition.id ? "updated" : "added"} successfully!`)
    },
    onSettled,
  })

  return { addMedStatement, isAdding }
}

export { useAddMedStatement }
