import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import { Dialog } from "primereact/dialog"
import { useState } from "react"

const RescheduleDialog = ({ title, onHide, onReschedule, initialDate = new Date(), isLoading }: Props) => {
  const [rescheduleDate, setRescheduleDate] = useState(initialDate)

  return (
    <Dialog
      closable={true}
      header={title}
      visible={true}
      draggable={false}
      dismissableMask={true}
      style={{ width: "35vw" }}
      onHide={onHide}
      footer={
        <div className="mt-2">
          <Button label="Cancel" className="button-default" onClick={onHide} disabled={isLoading} />
          <Button
            label="Reschedule"
            className="button-primary"
            loading={isLoading}
            onClick={() => {
              onReschedule(rescheduleDate.toISOString())
              onHide()
            }}
          />
        </div>
      }
    >
      <p className="mb-4">*Notes: Only active or on-hold orders can be rescheduled</p>
      <label>New date</label>
      <Calendar
        className="w-full mt-2"
        showIcon
        value={rescheduleDate}
        minDate={new Date()}
        dateFormat={"M d, yy"}
        onChange={(e) => setRescheduleDate(e.target.value as Date)}
      />
    </Dialog>
  )
}

type Props = {
  title: string
  initialDate?: Date
  onHide(): void
  onReschedule(rescheduleDate: string): void
  isLoading: boolean
}

export { RescheduleDialog }
