import { IconDefinition } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC } from "react"

const TabPanelHeader: FC<{ icon: IconDefinition; title: string }> = ({ icon, title }) => (
  <div className="flex items-center text-primary gap-1">
    <FontAwesomeIcon icon={icon} />
    <span className="text-sm">{title}</span>
  </div>
)

export { TabPanelHeader }
