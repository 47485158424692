import { CodeableConcept, ParametersParameterArrayArray } from "fhir"

const getDefaultIcd10Params = (icd10: CodeableConcept[] | undefined) => {
  const part = icd10?.reduce((acc, cc) => {
    return [...acc, { name: "icd10-code", value: { CodeableConcept: cc } }]
  }, Array<ParametersParameterArrayArray>())

  const defaultIcd10: ParametersParameterArrayArray[] = icd10?.length
    ? [{ name: "default-icd10-codes", part }]
    : [{ name: "remove-default-icd10-codes", value: { boolean: true } }]

  return defaultIcd10
}

export { getDefaultIcd10Params }
