const revocableTaskStatus = ["draft", "on-hold", "failed"]
const LAB_TASK_CODE = "order-labs"
const revocableOrderStatus = ["draft", "requisition-pending", "requisition-available"]

const LAB_FORM_TABS = {
  TESTS_TAB: {
    key: "tests-tab",
    index: 0,
  },
  ICD10_TAB: {
    key: "icd10-tab",
    index: 1,
  },
}

export { LAB_FORM_TABS, LAB_TASK_CODE, revocableOrderStatus, revocableTaskStatus }
