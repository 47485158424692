import { useEffect, useState } from "react"

const BREAK_POINT_TABLET = 768

const useWindowBounds = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  const isSmallScreen = screenWidth < BREAK_POINT_TABLET

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth)
  }

  const updateScreenHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty("--app-height", `${window.innerHeight}px`)
  }

  useEffect(() => {
    updateScreenHeight()

    window.addEventListener("resize", () => {
      updateScreenWidth()
      updateScreenHeight()
    })

    return () => {
      window.removeEventListener("resize", updateScreenWidth)
    }
  }, [isSmallScreen, screenWidth])

  return { isSmallScreen }
}

export { useWindowBounds }
