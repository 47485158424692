import { useMutation } from "@tanstack/react-query"
import { Practitioner } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

const useUpdatePractitioner = (reloadPractitionerData?: () => void, onSettled?: () => void) => {
  const { patch } = useClient()

  const patchPract = async (practitioner: Practitioner) =>
    patch("Practitioner", practitioner.id as string, practitioner)

  const { mutate: patchPractitioner, isPending: isUpdating } = useMutation({
    mutationFn: patchPract,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: () => {
      reloadPractitionerData?.()
      displayNotificationSuccess("Practitioner updated successfully")
      // datadogLogs.logger.info(`Practitioner ${practitioner.id} patched successfully`, { practitioner })
    },
    onSettled: onSettled,
  })

  return { patchPractitioner, isUpdating }
}

export { useUpdatePractitioner }
