import { faGearCode, faVials } from "@fortawesome/pro-regular-svg-icons"
import { codeableConceptAsString, Money, PlanDefinition } from "fhir"
import { Dialog } from "primereact/dialog"
import { TabPanel, TabView } from "primereact/tabview"
import { FC, ReactNode, useState } from "react"

import { TabPanelHeader } from "commons"
import { getMoneyCurrencyAlt, getPDReasonCodes } from "utils"

import { SkeletonLoader } from "../../components/SkeletonLoader"
import { TabPanelItem } from "../../types"
import { getPDSkuValue } from "../../utils"
import { LAB_FORM_TABS } from "../data"
import { LabComboTitle } from "./LabComboTitle"

const LabComboDetails: FC<Props> = ({
  title = "Combo details",
  definition,
  price,
  tests,
  isLoadingPanels,
  onHide,
  showPrice,
  headerSlot,
  styledPrice,
}) => {
  const icd10 = getPDReasonCodes(definition)

  const [activeTab, setActiveTab] = useState(LAB_FORM_TABS.TESTS_TAB.index)

  const tabPanelItems: TabPanelItem[] = [
    {
      id: LAB_FORM_TABS.TESTS_TAB.key,
      visible: true,
      header: { icon: faVials, title: "Tests" },
      content: (
        <section className="flex flex-col space-y-1">
          {isLoadingPanels ? (
            <SkeletonLoader repeats={2} loaderType="two-lines" />
          ) : (
            <ul className="@container ml-3">
              {tests.map((test, index) => (
                <div key={test.planDefinition.id ?? index} className="flex justify-between items-center">
                  <div className="py-2">
                    <div className="text-gray-600 font-semibold text-sm">{test.display}</div>
                    <div className="text-gray-400 text-xs">{`${test.planDefinition.publisher} - ${getPDSkuValue(test.planDefinition.identifier) ?? "no sku value"}`}</div>
                  </div>
                </div>
              ))}
            </ul>
          )}
        </section>
      ),
    },
    {
      id: LAB_FORM_TABS.ICD10_TAB.key,
      visible: !!icd10?.length,
      header: { icon: faGearCode, title: "Default ICD-10 Codes" },
      content: (
        <section className="flex flex-col space-y-1 pt-3">
          <ul className="@container ml-3">
            {icd10?.map((cc, index) => (
              <div key={cc.coding?.[0]?.code ?? index} className="items-center py-2">
                <div className="text-gray-600 font-semibold text-sm">{codeableConceptAsString(cc)}</div>
                <div className="text-gray-400 text-xs">{`${cc.coding?.[0]?.code}`}</div>
              </div>
            ))}
          </ul>
        </section>
      ),
    },
  ]

  return (
    <Dialog
      closable={true}
      header={title}
      visible={true}
      draggable={false}
      dismissableMask={false}
      style={{ width: "35vw" }}
      onHide={onHide}
    >
      <div className="flex flex-col">
        <div className="flex justify-between text-lg font-semibold text-gray-700 mb-2">
          <LabComboTitle combo={definition} />
          {showPrice && (
            <span className={styledPrice}>
              {getMoneyCurrencyAlt("USD")}
              {(price?.value ?? 0).toFixed(2)}
            </span>
          )}
        </div>
        <div className="flex justify-end font-semibold text-gray-600">{headerSlot}</div>
        <TabView
          className="sticky-header flex flex-col flex-1 -w-12"
          panelContainerClassName="h-full pb-0 flex-1"
          activeIndex={activeTab}
          onTabChange={(e) => setActiveTab(e.index)}
          scrollable
          pt={{
            nextButton: {
              className: "w-auto h-auto top-[35%] shadow-none",
            },
            nextIcon: {
              className: "text-primary",
            },
            prevButton: {
              className: "w-auto h-auto top-[35%] shadow-none first:text-primary",
            },
            navContainer: {
              className: "mb-4",
            },
          }}
        >
          {tabPanelItems.map(({ id, header: { icon, title }, visible, content, contentClassName }) => (
            <TabPanel
              key={id}
              header={<TabPanelHeader icon={icon} title={title} />}
              visible={visible}
              contentClassName={contentClassName}
            >
              {content}
            </TabPanel>
          ))}
        </TabView>
      </div>
    </Dialog>
  )
}

type Props = {
  title?: string
  definition: PlanDefinition
  price?: Money
  isLoadingPanels?: boolean
  showPrice?: boolean
  styledPrice?: string
  tests: { planDefinition: PlanDefinition; price?: Money; display?: string }[]
  headerSlot?: ReactNode
  onHide(): void
}

export { LabComboDetails }
