import { Address } from "fhir"

import { AddressField, InformationCard } from "commons"
import { SmartyAddressVerificationProvider } from "commons/context"
import { emptyAddress } from "data"
import { useLoginContext } from "security"
import { getAddressSchema, getHomeAddress, getStringAddress } from "utils"

import { ProfileSection } from "../data"
import { useUpdatePractitioner } from "../hooks"
import { sanitize } from "./validations"

const AddressInformation = () => {
  const { loggedInPractitioner, loggedInPractitionerId, reloadPractitioner } = useLoginContext()
  const { patchPractitioner, isUpdating } = useUpdatePractitioner(reloadPractitioner)

  const { address: practitionerAddress } = loggedInPractitioner

  const onSubmit = async (address: Address) => {
    patchPractitioner(
      sanitize({
        id: loggedInPractitionerId,
        address: [address, ...(loggedInPractitioner.address?.filter(({ use }) => use !== "home") ?? [])],
      }),
    )
  }

  const homeAddress = getHomeAddress(practitionerAddress)

  return (
    <InformationCard
      id={ProfileSection.ADDRESS}
      title="Address Information"
      data={{
        Home: getStringAddress(homeAddress),
      }}
      initialValue={homeAddress ?? emptyAddress}
      validationSchema={getAddressSchema()}
      isUpdating={isUpdating}
      onSubmit={onSubmit}
      className="profile-card-section p-4"
    >
      <SmartyAddressVerificationProvider>
        <fieldset className="relative p-fluid grid grid-cols-2 gap-4">
          <AddressField showTypeUseField={false} />
        </fieldset>
      </SmartyAddressVerificationProvider>
    </InformationCard>
  )
}

export { AddressInformation }
