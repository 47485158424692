import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AllergyIntolerance } from "fhir"
import { classNames } from "primereact/utils"
import { Dispatch, FC, ReactNode, SetStateAction, useEffect } from "react"

import {
  AddFieldArrayItemButton,
  DialogFormContainer,
  ModulesId,
  SkeletonLoader,
  StackedListContainer,
  useCrudReducer,
} from "commons"
import { useOpenEncounter } from "encounter"
import { useAppModuleContext } from "internals"
import { usePatientContext } from "patients"

import { useAllergies, useCreateAllergy } from "../hooks"
import { AllergiesForm } from "./AllergiesForm"
import { allergyModelBuilder } from "./allergyModelBuilder"
import { allergyValidationSchema, getInitialValues, sanitize } from "./validations"

const PatientAllergies: FC<Props> = ({
  onSubmit,
  header,
  className = "border-t mt-4 pt-4",
  title = "Patient allergies",
  forceReset,
  queryData,
  supportingInfo,
}) => {
  const { patientId, patientRef } = usePatientContext()
  const { appModules } = useAppModuleContext()
  const { openEncounterRef } = useOpenEncounter(patientId)

  const { showSlide, initialValue, reset, add } = useCrudReducer({
    defaultEntity: getInitialValues(patientRef, openEncounterRef),
  })

  const { allergies, isLoading } = useAllergies({ patientId, enabled: !queryData })
  const { createAllergy } = useCreateAllergy(reset)

  const handleSubmit = (allergy: AllergyIntolerance) => {
    if (onSubmit) onSubmit(allergy)
    else createAllergy(sanitize(allergy))
  }
  useEffect(() => {
    if (forceReset?.reset) {
      reset()

      forceReset?.setReset(false)
    }
  }, [forceReset])

  return (
    <div className={classNames("flex flex-col", className)}>
      {header ?? <span className="text-gray-900">{title}</span>}
      {queryData?.isLoading || isLoading ? (
        <SkeletonLoader repeats={2} loaderType="two-lines" />
      ) : (
        <div className="flex flex-col flex-1 overflow-y-auto bg-white">
          {queryData?.allergies?.length || allergies?.length ? (
            <StackedListContainer
              data={queryData?.allergies ?? allergies ?? []}
              itemModelBuilder={(item) => allergyModelBuilder(item)}
            />
          ) : (
            <div className="text-center m-auto">
              <FontAwesomeIcon icon={appModules[ModulesId.ALLERGY].getIcon()} size="2x" className="text-slate-400" />
              <h3 className="mt-2 text-sm font-semibold text-gray-900">No allergies found</h3>
            </div>
          )}
          <AddFieldArrayItemButton label={`Add ${title.toLocaleLowerCase()}`} onClick={add} />
        </div>
      )}
      <DialogFormContainer
        title={`Add ${title.toLocaleLowerCase()}`}
        showForm={showSlide}
        onSubmit={handleSubmit}
        onCancel={reset}
        initialValue={initialValue}
        validationSchema={allergyValidationSchema}
        useFormik
        supportingInfo={supportingInfo}
      >
        <AllergiesForm />
      </DialogFormContainer>
    </div>
  )
}

type Props = {
  title?: string
  header?: ReactNode
  className?: string
  onSubmit?(allergy: AllergyIntolerance): void
  queryData?: { allergies?: AllergyIntolerance[]; isLoading?: boolean }
  forceReset?: { reset: boolean; setReset: Dispatch<SetStateAction<boolean>> }
  supportingInfo?: ReactNode
}

export { PatientAllergies }
