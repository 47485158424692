import { CodeableConcept, MedicationStatement, Reference } from "fhir"
import * as Yup from "yup"

const getInitialValues = (patientRef: Reference, encounter?: Reference): MedicationStatement => ({
  medication: { CodeableConcept: undefined },
  status: "unknown",
  subject: patientRef,
  effective: { dateTime: undefined },
  dateAsserted: undefined,
  reasonCode: [{ coding: undefined, text: undefined }],
  note: [
    {
      text: "",
      time: new Date().toISOString(),
    },
  ],
  encounter,
  resourceType: "MedicationStatement",
})

const medicationStatementValidationSchema = Yup.object().shape({
  medication: Yup.object().shape({
    CodeableConcept: Yup.object().test(
      "test-codeable-concept",
      "Medication is required",
      (value: CodeableConcept | undefined) => value?.coding !== undefined,
    ),
  }),
  subject: Yup.object().required("Medication subject is required"),
  reasonCode: Yup.array().of(
    Yup.object().test(
      "test-codeable-concept-reason-code",
      "Reason is required",
      (value: CodeableConcept | undefined) => value?.coding !== undefined,
    ),
  ),
  effective: Yup.object().shape({
    dateTime: Yup.string().required("Effective is required"),
  }),
})

const sanitize = ({ ...medicationStatement }: MedicationStatement) => {
  medicationStatement.dateAsserted = new Date().toISOString()

  if (!medicationStatement.status) medicationStatement.status = "unknown"
  if (!medicationStatement.note?.[0]?.text) {
    delete medicationStatement.note
  }
  if (!medicationStatement.effective?.dateTime) delete medicationStatement.effective
  if (!medicationStatement.reasonCode?.[0].text) delete medicationStatement.reasonCode
  if (!medicationStatement.encounter) delete medicationStatement.encounter

  return medicationStatement
}

export { getInitialValues, medicationStatementValidationSchema, sanitize }
