import { faCalendarClock, faSearch, faSpinner } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format } from "date-fns"
import { MedicationKnowledge, MedicationRequest } from "fhir"
import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox"
import { ConfirmDialog } from "primereact/confirmdialog"
import { classNames } from "primereact/utils"
import { startTransition, useEffect, useId, useReducer, useState } from "react"
import { useParams } from "react-router-dom"

import { Input, MedicationKnowledgeDetails, SkeletonLoader, useChargeItemDefinitions } from "commons"
import { useMedicationRequestBindByDate, useMedicationRequests, useRescheduleMedicationRequest } from "commons/meds"
import { formatsByTypes } from "data"
import { useCPOEContext } from "orders"
import { useOrganizationContext } from "organization"

import { MedicationRequestListItem } from "./medications/MedicationRequestListItem"

const MedicationRequestsRescheduleView = () => {
  const { patientId } = useParams()
  const cpoeCtx = useCPOEContext()
  const { currentOrganizationId } = useOrganizationContext()

  const {
    searchFilter,
    billToPatientCIDsForReschedule,
    rescheduleDate,
    updateRescheduleDate,
    updateChecked,
    updateSearchText,
  } = useReducerState()
  const [selectedMK, setSelectedMK] = useState<{ mk?: MedicationKnowledge; mr?: MedicationRequest }>()

  const {
    medicationRequests,
    medicationDispenses,
    medicationKnowledges,
    isLoading: isLoadingMR,
    isFetchingNextPage,
    medCodes,
    reloadMedications,
  } = useMedicationRequests({ patientId: patientId as string, category: "nutraceutical", statusFilter: ["active"] })
  const { chargeItemDefinitions } = useChargeItemDefinitions({
    organizationId: currentOrganizationId,
    codes: {
      billToPracticeOrInsuranceCIDs: medCodes,
    },
  })

  const { medicationRequestDataByDate } = useMedicationRequestBindByDate(
    medicationRequests,
    medicationKnowledges,
    chargeItemDefinitions?.billToPracticeOrInsuranceCIDs ?? {},
    medicationDispenses,
    searchFilter,
  )

  useEffect(() => {
    if (cpoeCtx.ordersCount >= 0) reloadMedications()
  }, [cpoeCtx.ordersCount])

  const { rescheduleMedicationRequest, isLoading: isRescheduling } = useRescheduleMedicationRequest(() =>
    updateChecked([]),
  )

  const rescheduleMRs = () => {
    if (rescheduleDate)
      rescheduleMedicationRequest({
        rescheduleDate: format(rescheduleDate, formatsByTypes.ISO_8601_DATE),
        medicationRequests: billToPatientCIDsForReschedule,
      })
  }

  const loaderKey = useId()
  const loader = () => (
    <SkeletonLoader
      key={loaderKey}
      repeats={3}
      containerClassName="p-5 overflow-auto"
      loaderType="list"
      skeletonShape="rectangle"
      skeletonSize="6rem"
      extraLine
    />
  )

  const handleCheck = (checkEvent: CheckboxChangeEvent) => {
    let _selectedMRs = [...billToPatientCIDsForReschedule]

    if (checkEvent.checked) {
      if (Array.isArray(checkEvent.value)) _selectedMRs = _selectedMRs.concat(checkEvent.value)
      else _selectedMRs.push(checkEvent.value)
    } else {
      const updatedMRs = _selectedMRs.reduce((prev, mrId) => {
        if (mrId === checkEvent.value || (Array.isArray(checkEvent.value) && checkEvent.value.includes(mrId)))
          return prev

        return [...prev, mrId]
      }, [] as string[])
      _selectedMRs = updatedMRs
    }

    updateChecked(_selectedMRs)
  }

  const areAllMRChecked = (mrIds: string[]) => {
    let allChecked = true
    for (let index = 0; index < mrIds.length && allChecked; index++) {
      const element = mrIds[index]
      allChecked = billToPatientCIDsForReschedule.includes(element)
    }

    return allChecked && billToPatientCIDsForReschedule.length > 0
  }

  return (
    <div className="flex flex-col h-full overflow-hidden p-3">
      <div className="inline-flex gap-2 min-w-32 w-full h-10 justify-end mb-3">
        <div className="relative block w-fit">
          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
            <FontAwesomeIcon className="text-slate-400" icon={faSearch} />
          </span>
          <Input
            placeholder="Search"
            className="pl-9 pr-3 h-full"
            type={!isLoadingMR ? "search" : undefined}
            onChange={(event) => {
              startTransition(() => {
                updateSearchText(event.currentTarget?.value)
              })
            }}
          />
          <span
            className={classNames("absolute inset-y-0 right-0 flex items-center pr-3", {
              hidden: !isLoadingMR || !isFetchingNextPage,
            })}
          >
            <FontAwesomeIcon className="text-slate-400" icon={faSpinner} spin />
          </span>
        </div>
        <span className="inline-flex p-inputgroup w-fit">
          <Calendar
            value={rescheduleDate}
            onChange={(e) => updateRescheduleDate(Array.isArray(e.value) ? e.value[0] : e.value)}
            disabled={billToPatientCIDsForReschedule.length === 0}
            minDate={new Date()}
          />
          <Button
            label="Reschedule"
            className="p-button-sm button-primary focus:outline-none"
            disabled={billToPatientCIDsForReschedule.length === 0 || !rescheduleDate}
            icon={<FontAwesomeIcon icon={faCalendarClock} className="mr-1" />}
            onClick={rescheduleMRs}
            loading={isRescheduling}
          />
        </span>
      </div>

      <>
        {isLoadingMR && !isFetchingNextPage ? (
          loader()
        ) : !medicationRequestDataByDate?.length ? (
          <div className="flex items-center justify-center w-full h-full bg-white">
            <div className="flex flex-col items-center">
              <FontAwesomeIcon icon={faSearch} size="3x" className="text-gray-400" />
              <h6 className="font-semibold text-gray-400 text-xl pt-4 pb-2">
                No prescriptions available for reschedule
              </h6>
              <h4 className="text-gray-400">All active prescriptions are being processed</h4>
            </div>
          </div>
        ) : (
          <div className="flex flex-col h-full overflow-auto grow">
            {medicationRequestDataByDate.map((data) => (
              <div className="flex flex-col w-full relative" key={data.date}>
                <div className="justify-center bg-white py-1 px-2 mr-1 border-b text-slate-400 text-lg items-end">
                  <Checkbox
                    inputId={data.date}
                    name="allforReschedule"
                    value={data.mrIds}
                    onChange={handleCheck}
                    checked={areAllMRChecked(data.mrIds)}
                    className="mr-3"
                  />
                  {data.date}
                </div>

                {data.medicationsData.map((medication) => {
                  const mrId = medication.medicationRequestInfo.id
                  const isChecked = billToPatientCIDsForReschedule.some((item) => item === mrId)

                  return (
                    <div
                      key={mrId}
                      className="inline-flex items-center w-full px-3 border-b border-slate-300 last-of-type:border-b-0"
                    >
                      <Checkbox
                        inputId={mrId}
                        name="forReschedule"
                        value={mrId}
                        onChange={handleCheck}
                        checked={isChecked}
                        disabled={medication.medicationRequestInfo.status !== "active"}
                      />
                      <MedicationRequestListItem
                        medicationData={medication}
                        onSelectMK={() =>
                          setSelectedMK({ mk: medication.medicationKnowledge, mr: medication.medicationRequestInfo })
                        }
                        isLoading={isRescheduling && isChecked}
                        showActions={false}
                      />
                    </div>
                  )
                })}
              </div>
            ))}
          </div>
        )}
      </>
      <ConfirmDialog />
      <MedicationKnowledgeDetails
        selectedMK={selectedMK?.mk}
        mr={selectedMK?.mr}
        onHide={() => setSelectedMK(undefined)}
      />
    </div>
  )
}

type State = {
  searchFilter?: string
  billToPatientCIDsForReschedule: string[]
  rescheduleDate?: Date
  selectedMK?: MedicationKnowledge
}

const initialState = {
  searchFilter: undefined,
  billToPatientCIDsForReschedule: [],
  rescheduleDate: new Date(),
  selectedMK: undefined,
} as State

const reducer = (
  state: State,
  {
    type,
    payload,
  }: {
    type: "update-search-filter" | "change-page" | "update-checked" | "update-date" | "update-selected-mk"
    payload: string | number | string[] | Date | MedicationKnowledge | undefined | null
  },
) => {
  switch (type) {
    case "update-search-filter":
      return {
        ...state,
        searchFilter: payload as string,
      }
    case "update-checked":
      return {
        ...state,
        billToPatientCIDsForReschedule: payload as string[],
      }

    case "update-date":
      return {
        ...state,
        rescheduleDate: payload as Date | undefined,
      }
    case "update-selected-mk":
      return { ...state, selectedMK: payload as MedicationKnowledge | undefined }
    default:
      return state
  }
}

const useReducerState = () => {
  const [{ searchFilter, billToPatientCIDsForReschedule, rescheduleDate, selectedMK }, dispatch] = useReducer(
    reducer,
    initialState,
  )

  const updateSearchText = (filter: string) => {
    dispatch({ type: "update-search-filter", payload: filter })
  }

  const updateChecked = (payload: string[]) => {
    dispatch({ type: "update-checked", payload })
  }

  const updateRescheduleDate = (payload?: Date | null | string) => {
    dispatch({ type: "update-date", payload })
  }

  const updateSelectedMK = (payload?: MedicationKnowledge) => {
    dispatch({ type: "update-selected-mk", payload })
  }

  return {
    searchFilter,
    billToPatientCIDsForReschedule,
    rescheduleDate,
    selectedMK,
    updateSelectedMK,
    updateRescheduleDate,
    updateChecked,
    updateSearchText,
  }
}

export { MedicationRequestsRescheduleView }
