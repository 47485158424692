import { MedicationStatement } from "fhir"
import { FC, useId } from "react"

import {
  DataContainerSlideoverForm,
  InfiniteScroll,
  ModulesId,
  SkeletonLoader,
  StackedListContainer,
  useCrudReducer,
} from "commons"
import { useOpenEncounter } from "encounter"
import { useAppModuleContext } from "internals"
import { usePatientContext } from "patients"

import { useCreateMedicationStatement, useMedicationsStatement, useUpdateMedicationStatement } from "../hooks"
import { MedicationStatementForm } from "./MedicationStatementForm"
import { medicationModel } from "./medStatementModuelBuilder"
import { getInitialValues, medicationStatementValidationSchema, sanitize } from "./validations"

const MedicationsStatementContainer: FC = () => {
  const loaderKey = useId()
  const { patientId, patientRef } = usePatientContext()
  const { appSubModules } = useAppModuleContext()
  const { openEncounterRef } = useOpenEncounter(patientId)

  const { reset, add, edit, showSlide, initialValue, isNew } = useCrudReducer({
    defaultEntity: getInitialValues(patientRef, openEncounterRef),
  })

  const { medications, isLoading, hasNextPage, fetchNextPage } = useMedicationsStatement({ patientId })
  const { createMedication } = useCreateMedicationStatement(reset)
  const { updateMedicationStatement } = useUpdateMedicationStatement(reset)

  const onSubmit = (medication: MedicationStatement) => {
    isNew ? createMedication(sanitize(medication)) : updateMedicationStatement(sanitize(medication))
  }

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  if (isLoading) return loader()

  function deactivate(medication: MedicationStatement): void {
    const sanitizedMedication = sanitize({ ...medication, status: "entered-in-error" })
    updateMedicationStatement(sanitizedMedication)
  }

  return (
    <DataContainerSlideoverForm
      hasData={!!medications?.length}
      showSlide={showSlide}
      formTitle="Medication Statement"
      formInitialValue={initialValue}
      validationSchema={medicationStatementValidationSchema}
      onSubmit={onSubmit}
      onCancel={reset}
      form={<MedicationStatementForm isEditing={!isNew} />}
      onButtonAddClick={add}
      iconDataNotFound={appSubModules[ModulesId.INTAKE][ModulesId.MEDICATIONE].getIcon()}
    >
      <div className="bg-white h-full overflow-auto">
        <InfiniteScroll loadMore={() => fetchNextPage()} hasMore={hasNextPage} loader={loader()}>
          <StackedListContainer
            data={medications ?? []}
            itemModelBuilder={(item) =>
              medicationModel(
                item,
                () => edit(item),
                () => deactivate(item),
              )
            }
          />
        </InfiniteScroll>
      </div>
    </DataContainerSlideoverForm>
  )
}

export { MedicationsStatementContainer }
