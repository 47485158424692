import { FormikHelpers } from "formik"
import { useMountEffect } from "primereact/hooks"
import { useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { Button, ConfirmDialog, FormContainer, ModulesId } from "commons"
import { useAppModuleContext } from "modules"
import { useOrganizationContext, useOrganizationPractitioners } from "organization"
import { usePatientContext } from "patients"
import { areAddressesSimilars } from "utils"

import { MedicationRequestFormData } from "../../types"
import { getPractitionersInfoWithNPIAndLifefileId, prescriptionValidationSchema } from "../../utils"
import { PrescriptionAddressWarningMessage } from "./PrescriptionAddressWarningMessage"
import { PrescriptionForm } from "./PrescriptionForm"

const PrescriptionFormContainer = ({ initialValues, isEditing, editAddressHidden, onSubmit, onCancel }: Props) => {
  const { currentOrganizationId, currentOrganizationBillingAddress: organizationAddress } = useOrganizationContext()
  const { isModuleActive } = useAppModuleContext()
  const { organizationPractitionersInfo } = useOrganizationPractitioners({ organizationId: currentOrganizationId })
  const practionersInfoWithValidNPIAndLifefileId = useMemo(
    () => getPractitionersInfoWithNPIAndLifefileId(organizationPractitionersInfo),
    [organizationPractitionersInfo],
  )
  const { patient } = usePatientContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const [mrData, setMRData] = useState<MedicationRequestFormData | undefined>(undefined)

  const missingPatientAddress = !patient.address?.length
  const patientHasSameAddressAsPractice = patient.address?.some(
    (address) => organizationAddress && areAddressesSimilars(address, organizationAddress),
  )

  useMountEffect(() => {
    if (isModuleActive(ModulesId.INTAKE) && !["allergies", "conditions"].includes(searchParams.get("kp") as string)) {
      searchParams.set("kp", "allergies")
      setSearchParams(searchParams)
    }
  })

  return (
    <div className="grow min-h-0">
      <FormContainer
        title={`${isEditing ? "Update" : "Create"} Prescription`}
        initialValue={initialValues}
        onSubmit={onSubmit}
        onCancel={onCancel}
        hideButtonsDivider
        saveLabel={`${isEditing ? "Update" : "Create"} Prescription`}
        validationSchema={prescriptionValidationSchema(practionersInfoWithValidNPIAndLifefileId)}
        innerContainerClassName="px-4 space-y-4 pb-6"
        footerClassName="border-t border-gray-200"
        disableSave={missingPatientAddress || patientHasSameAddressAsPractice}
        customSaveButton={({ values, validate, isSubmitting }) => {
          const shouldShowAlert =
            ["capsule", "tablet", "troche", "capsule-er", "rapid-odt", "tablet-er"].includes(
              values.prescriptionQuantity?.code ?? "",
            ) && values.dispenseRequest?.quantity?.value === 1

          return (
            <Button
              label={`${isEditing ? "Update" : "Create"} Prescription`}
              type={shouldShowAlert ? "button" : "submit"}
              loading={isSubmitting}
              disabled={missingPatientAddress || patientHasSameAddressAsPractice}
              onClick={
                shouldShowAlert
                  ? async () => {
                      const errors = await validate()
                      if (!Object.keys(errors).length) setMRData(values)
                    }
                  : undefined
              }
            />
          )
        }}
      >
        {({ isSubmitting, setSubmitting }) => (
          <>
            {missingPatientAddress && (
              <PrescriptionAddressWarningMessage
                message="In order to create a prescription the patient should have an address on file."
                actionText="Add it here."
              />
            )}
            {patientHasSameAddressAsPractice && (
              <PrescriptionAddressWarningMessage
                message="⚠️ Patient addresses must not be the same as the practice address"
                actionText="Change it here."
              />
            )}
            <PrescriptionForm
              isEditing={isEditing}
              practitionersInfo={practionersInfoWithValidNPIAndLifefileId}
              editAddressHidden={editAddressHidden}
            />

            <ConfirmDialog
              confirmText={`You are creating a prescription with only 1 ${mrData?.prescriptionQuantity?.code}.  Are you sure you wish to proceed?`}
              actionName="Proceed"
              actionType="submit"
              visible={!!mrData}
              isLoading={isSubmitting}
              hideDialog={() => {
                setMRData(undefined)
                setSubmitting(false)
              }}
              waitToFinish
              appendTo="self"
            />
          </>
        )}
      </FormContainer>
    </div>
  )
}

type Props = {
  initialValues: MedicationRequestFormData
  isEditing?: boolean
  onSubmit(_: MedicationRequestFormData, formikHelpers?: FormikHelpers<MedicationRequestFormData>): void
  isSubmitting?: boolean
  onCancel(): void
  editAddressHidden?: boolean
}

export { PrescriptionFormContainer }
