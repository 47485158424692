import { IconDefinition } from "@fortawesome/pro-light-svg-icons"

import { allergyModelBuilder, useAllergies } from "allergies"
import { StackedListContainer } from "commons"

import { WrapUpSection } from "./WrapUpSection"

const AllergiesSection = ({ patientId, encounterId, icon }: Props) => {
  const { allergies, isLoading, total } = useAllergies({ patientId, key: "KP", encounter: encounterId })

  return (
    <WrapUpSection
      subSectionTitle="Allergies"
      isLoading={isLoading}
      isEmpty={!total}
      emptyMesage="No allergies added during this encounter"
      icon={icon}
      className="w-3/4 self-end"
    >
      <StackedListContainer data={allergies ?? []} itemModelBuilder={(item) => allergyModelBuilder(item)} />
    </WrapUpSection>
  )
}

type Props = {
  patientId: string
  encounterId: string
  icon: IconDefinition
}

export { AllergiesSection }
