import { useMutation, useQueryClient } from "@tanstack/react-query"
import { ListEntryArray, Parameters } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { settingsQueryKeys } from "../../query-keys"
import { PDParams } from "../../types"
import { getDefaultIcd10Params } from "./utils"

const useCreateCombo = (onSettled?: () => void) => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()

  const newCombo = async ({
    organization,
    basePrice,
    laboratory,
    title,
    panels,
    fee,
    gender,
    parentCombo,
    status,
    icd10,
  }: PDParams) => {
    const panelsEntry = panels.reduce((acc, ref) => {
      return [...acc, { item: ref }]
    }, Array<ListEntryArray>())

    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "organization",
          value: {
            Reference: organization,
          },
        },
        {
          name: "lab",
          value: {
            Reference: laboratory,
          },
        },
        ...(parentCombo
          ? [
              {
                name: "replaces",
                value: {
                  Reference: parentCombo,
                },
              },
            ]
          : []),
        {
          name: "panels",
          resource: {
            resourceType: "List",
            mode: "Working",
            status: "Current",
            entry: panelsEntry,
          },
        },
        {
          name: "title",
          value: {
            string: title,
          },
        },
        ...(gender
          ? [
              {
                name: "gender",
                value: {
                  string: gender,
                },
              },
            ]
          : []),
        {
          name: "base-price",
          value: {
            decimal: basePrice,
          },
        },
        {
          name: "fee",
          value: {
            decimal: fee ?? 0,
          },
        },
        {
          name: "status",
          value: {
            code: status,
          },
        },
        ...getDefaultIcd10Params(icd10),
      ],
    }

    return operationRequest({
      endpoint: "PlanDefinition",
      method: "POST",
      operation: "create-combo",
      parameters,
    })
  }

  const { mutate: createCombo, isPending } = useMutation({
    mutationFn: newCombo,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { organization }) => {
      await queryClient.invalidateQueries({
        queryKey: settingsQueryKeys.labCombos(organization.id as string),
        refetchType: "all",
      })
      displayNotificationSuccess("Lab combo created successfully!")
      // datadogLogs.logger.info(`Lab combo on ${organization.id} created successfully!`, { laboratory })
    },
    onSettled,
  })

  return { createCombo, isCreating: isPending }
}

export { useCreateCombo }
