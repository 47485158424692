import { asReference, Organization, Reference } from "fhir"
import * as Yup from "yup"

import { getPDReasonCodes } from "utils"

import { LabCombo, LabPanel, PDParams } from "../../types"

const getComboInitialValues = (
  organization: Organization,
  isClone: boolean = false,
  labCombo?: LabCombo,
): Partial<PDParams> => {
  const laboratory = labCombo?.performerLab

  const gender = labCombo?.combo?.useContext?.find(({ code }) => code.code === "gender")?.value?.CodeableConcept
    ?.coding?.[0]?.code

  const panels = labCombo?.tests?.map((pd) => asReference(pd?.planDefinition))

  const parentCombo = isClone && labCombo ? asReference(labCombo?.combo) : undefined

  const basePrice = labCombo ? labCombo.price : 0
  const fee = labCombo ? labCombo.fee : 0
  const id = labCombo?.combo?.id

  const icd10 = labCombo?.combo && getPDReasonCodes(labCombo?.combo)

  return {
    organization: asReference(organization),
    laboratory,
    fee,
    basePrice,
    gender: gender as "male" | "female" | undefined,
    parentCombo,
    title: labCombo?.combo?.title,
    panels,
    tests: labCombo?.tests,
    id,
    icd10,
  }
}

const sanitizeCombo = (data: PDParams): PDParams => {
  const panels = data.tests?.map(({ planDefinition }) => asReference(planDefinition)) ?? []

  return { ...data, panels }
}

const comboValidationSchema = Yup.object().shape({
  title: Yup.string().required("Combo name is required"),
  laboratory: Yup.object().test(
    "test-reference",
    "Laboratory is required",
    (value: Reference) => value?.id !== undefined,
  ),
  tests: Yup.array().test(
    "panels-added",
    "At least one tests is required. Ensure you already have a Laboratory selected",
    (panels) => {
      return !!panels?.length
    },
  ),
})

const getPanelInitialValues = (organization: Organization, labPanel?: LabPanel): Partial<PDParams> => {
  const laboratory = labPanel?.performerLab

  const basePrice = labPanel ? labPanel.price : 0
  const fee = labPanel ? labPanel.fee : 0
  const id = labPanel?.planDefinition?.id

  return {
    organization: asReference(organization),
    laboratory,
    fee,
    basePrice,
    title: labPanel?.planDefinition?.title,
    id,
  }
}

export { comboValidationSchema, getComboInitialValues, getPanelInitialValues, sanitizeCombo }
