import { Consent } from "fhir"
import { FC, useState } from "react"

import { consentModelBuilder, useConsentDocument } from "consent"

import { StackedListContainer } from "../../../components/StackedListContainer"

const CPConsentsDetails: FC<Props> = ({ consents }) => {
  const [selectedConsent, setSelectedConsent] = useState<string>()
  const { getConsentDocument, isGettingDR } = useConsentDocument()

  const handleDownloadConsentAttachemnt = (consent: Consent) => {
    setSelectedConsent(consent.id as string)
    getConsentDocument(consent.id as string)
  }

  return (
    <StackedListContainer
      data={consents}
      className="pt-1"
      itemModelBuilder={(item) =>
        consentModelBuilder({
          consent: item,
          download: () => handleDownloadConsentAttachemnt(item),
          isLoading: isGettingDR && selectedConsent === item.id,
          showAsExternalAction: true,
          linetItemClassname: "text-gray-500 text-sm",
        })
      }
    />
  )
}

type Props = {
  consents: Consent[]
}

export { CPConsentsDetails }
