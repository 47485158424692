import { ClientProvider, QuestionnaireResponseContainer } from "@chartedhealth/survey-components"
import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"

import { useClient } from "api"
import { useChartContext } from "chart-view"
import { datadogLogs } from "logger"
import { useAuth } from "security"

import "@chartedhealth/survey-components/dist/index.css"

const SurveyContainer = ({ qResponseId, onFinish, pickedQuestions }: Props) => {
  const { user } = useAuth()
  const chartContext = useChartContext()
  const { search, update, operationRequest, read, patch } = useClient()
  const [searchParam] = useSearchParams()
  const qrId = qResponseId ?? searchParam.get("qrId") ?? ""

  useEffect(() => {
    chartContext.setSearchData({ showSearch: false })
  }, [])

  return (
    <ClientProvider
      apm={datadogLogs.logger}
      loggedInUserResource={user?.linkedResource}
      search={search}
      operationRequest={operationRequest}
      read={read}
      patch={patch}
      update={update}
    >
      <QuestionnaireResponseContainer questionnaireId={qrId} onFinish={onFinish} pickedQuestions={pickedQuestions} />
    </ClientProvider>
  )
}

type Props = { qResponseId?: string; onFinish?(): void; pickedQuestions?: string[] }

export { SurveyContainer }
